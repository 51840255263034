<template>
  <div>
    <transition name="snackbar-appearance">
      <div
        v-if="errorText"
        class="md:right-24 right-16 md:bottom-24 bottom-16 md:left-24 left-16 bg-red z-modal py-8 rounded-md flex justify-center items-center text-xsmall sm:text-small-regular text-black fixed px-48"
      >
        <ExclamationTriangleIcon class="h-20 w-20 mr-8 absolute left-16 top-6 sm:top-8" />
        {{ errorText }}
        <GlobalsLinkHelper
          v-if="customerServicePage && !isMobile && !hideCustomerService"
          class="ml-8 text-small-medium underline"
          :to="customerServicePage.url"
        >
          {{ errorCta }}
        </GlobalsLinkHelper>
        <XMarkIcon class="absolute right-16 h-20 w-20 cursor-pointer select-none" @click="errorStore.clearError" />
      </div>
      <div
        v-else-if="successText"
        class="md:right-24 right-16 md:bottom-24 bottom-16 md:left-24 left-16 bg-success z-modal h-40 rounded-md flex justify-center items-center text-small-regular text-white fixed"
      >
        {{ successText }}
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">

import { computed } from 'vue';
import ExclamationTriangleIcon from '@heroicons/vue/24/outline/esm/ExclamationTriangleIcon';
import XMarkIcon from '@heroicons/vue/24/outline/esm/XMarkIcon';
import { useErrorStore } from '~/store/error';
import { useGlobalContentStore } from '~/store/globalContent';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';

const errorStore = useErrorStore();
const globalContentStore = useGlobalContentStore();
const customerServicePage = globalContentStore.pageSettings?.customerServicePage;
const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

defineProps<{
  successText?: string,
}>();

const errorText = computed(() => {
  return errorStore.errorMessage;
});

const hideCustomerService = computed(() => {
  return errorStore.hideCustomerService;
});

const errorCta = computed(() => {
  return globalContentStore.sharedResources.genericErrorCta;
});

watch(
  () => errorText.value,
  () => {
    if (errorText.value) {
      setTimeout(() => errorStore.clearError(), 10000);
    }
  }
);

</script>

<style>
.snackbar-appearance-enter-active,
.snackbar-appearance-leave-active {
  transition: all 0.3s ease-out;
}

.snackbar-appearance-enter-from,
.snackbar-appearance-leave-to {
  opacity: 0;
  transform: translateY(40%);
}
</style>
